import React from 'react'
import styled from 'styled-components'
import { RichText } from 'prismic-reactjs'

import SEO from '../components/SEO'

import Container from '../styles/Container'

const Title = styled.h1`
  margin-top: 67px;
  margin-bottom: 120px;
  font-family: Dignitas, sans-serif;
  font-weight: 700;
  font-size: 44px;
  line-height: 47px;
  letter-spacing: 0.25px;
  color: #0859ff;
  @media (min-width: 680px) {
    font-size: 80px;
    line-height: 90px;
    letter-spacing: 1.67px;
  }
  @media (min-width: 1200px) {
    margin-top: 160px;
    margin-bottom: 160px;
    font-size: 120px;
    line-height: 130px;
    letter-spacing: 2.5px;
  }
`

const OuterContainer = styled.div`
  background-color: #fdfbf9;
`

const Text = styled.div`
  margin-top: 40px;
  margin-bottom: 60px;
  @media (min-width: 1200px) {
    margin-top: 90px;
    margin-bottom: 100px;
  }
  strong {
    font-weight: 700;
  }
  p {
    margin-bottom: 22px;
    font-size: 14px;
    line-height: 22px;
    font-weight: 100;
    color: #002c5e;
    @media (min-width: 680px) {
      letter-spacing: 0.39px;
    }
    @media (min-width: 1200px) {
      margin-bottom: 26px;
      font-size: 18px;
      line-height: 26px;
      letter-spacing: 0.5px;
    }
  }
  h1 {
    margin-bottom: 8px;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.17px;
    font-weight: 700;
    color: #002c5e;
    @media (min-width: 1200px) {
      font-size: 20px;
      line-height: 28px;
      letter-spacing: 0.25px;
    }
  }
  a {
    font-weight: 700;
  }
`

const TextTable = styled.div`
  display: flex;
  flex-wrap: wrap;
  p {
    margin-bottom: 0;
    padding: 5px 10px 7px;
    flex: 0 0 50%;
    border-bottom: 1px #aaa solid;
  }
  p:nth-child(2n) {
    border-left: 1px #aaa solid;
  }
`

const text1 = [{"type":"heading1","text":"This Privacy Policy was last updated on 04/11/2019.","spans":[{"start":0,"end":51,"type":"strong"}]},
  {"type":"paragraph","text":"What is the purpose of this document?","spans":[{"start":0,"end":37,"type":"strong"}]},
  {"type":"paragraph","text":"Oros Education Limited (trading as Oros Education and previously Oros) is committed to protecting the privacy and security of your personal information.","spans":[]},
  {"type":"paragraph","text":"We operate the Oros Education platform which allows tutors to deliver their teaching services to the students of schools and other education providers based overseas.","spans":[]},
  {"type":"paragraph","text":"This privacy notice describes how we collect and use personal information about you during and after your working relationship with us, in accordance with the General Data Protection Regulation (GDPR).","spans":[{"start":195,"end":199,"type":"strong"}]},
  {"type":"paragraph","text":"This privacy notice applies to all tutors who work via our platform. It also applies to all visitors to our website at oros-education.com and toptutor.com.","spans":[{"start":119,"end":137,"type":"strong"}]},
  {"type":"paragraph","text":"Oros Education Limited is the \"data controller\". This means that we are responsible for deciding how we hold and use personal information about you. We are required under data protection laws to notify you of the information contained in this privacy notice. ","spans":[]},
  {"type":"paragraph","text":"We are a company registered in England and Wales under company registration number 12047806. Our registered office address is at 34 Bloomsbury Street, London, WC1B 3QJ. You can contact us by writing to us at that address. Alternatively, you can call us on +44 07741310310 or email us at harry@oros-education.com. We are also registered with the UK Information Commissioner’s Office under registration number ZA557682.","spans":[]},
  {"type":"paragraph","text":"This notice applies to our current and former tutors and to visitors to our website. This notice does not form part of any contract to provide services. We may update this notice at any time but if we do so, we will let you know as soon as reasonably practical.","spans":[]},
  {"type":"paragraph","text":"It is important that you read and retain this notice, together with any other privacy notice we may provide on specific occasions when we are collecting or processing personal information about you, so that you are aware of how and why we are using such information and what your rights are under data protection laws.","spans":[]},
  {"type":"paragraph","text":"To enable us to provide our services to you and to enable you to deliver your lessons via our platform, we will have to share some of your personal information with our clients based overseas with whom we have a business-to-business relationship. Our clients may be schools, education providers or other businesses operating in the education sector in overseas jurisdictions. They are predominantly based in China. We will, as part of our arrangements with our clients, require them to keep your personal information secure and we will require them to only use your personal information for the purposes of promoting and delivering our platform and services.  ","spans":[]},
  {"type":"paragraph","text":"Data protection principles","spans":[{"start":0,"end":26,"type":"strong"}]},
  {"type":"paragraph","text":"We will comply with data protection law. This means that the personal information we hold about you must be:","spans":[]},
  {"type":"paragraph","text":"1. Used lawfully, fairly and in a transparent way.","spans":[]},
  {"type":"paragraph","text":"2. Collected only for valid purposes that we have clearly explained to you and not used in any way that is incompatible with those purposes.","spans":[]},
  {"type":"paragraph","text":"3. Relevant to the purposes we have told you about and limited only to those purposes.","spans":[]},
  {"type":"paragraph","text":"4. Accurate and kept up to date.","spans":[]},
  {"type":"paragraph","text":"5. Kept only as long as necessary for the purposes we have told you about.","spans":[]},
  {"type":"paragraph","text":"6. Kept securely.","spans":[]},
  {"type":"paragraph","text":"The kind of information we hold about you","spans":[{"start":0,"end":41,"type":"strong"}]},
  {"type":"paragraph","text":"Personal data, or personal information, means any information about an individual from which that person can be identified. It does not include data where the identity has been removed (anonymous data).","spans":[]},
  {"type":"paragraph","text":"There are certain types of more sensitive personal data which require a higher level of protection, such as information about a person's health or sexual orientation. Information about criminal convictions also warrants this higher level of protection.","spans":[]},
  {"type":"paragraph","text":"We will collect, store, and use the following categories of personal information about you:","spans":[]},
  {"type":"paragraph","text":"Personal contact details such as title, first names, surname, addresses, telephone numbers, and personal email addresses.","spans":[]},
  {"type":"paragraph","text":"Date of birth.","spans":[]},
  {"type":"paragraph","text":"Gender.","spans":[]},
  {"type":"paragraph","text":"Bank account details and payroll records.","spans":[]},
  {"type":"paragraph","text":"The date you started working via our platform.","spans":[]},
  {"type":"paragraph","text":"The history of the work you have carried out via our platform.","spans":[]},
  {"type":"paragraph","text":"Recruitment information (including copies of right to work documentation, references and other information included in a CV or cover letter or as part of the application process).","spans":[]},
  {"type":"paragraph","text":"Details of your employment history, professional experience and achievements (including job titles, work history and professional memberships).","spans":[]},
  {"type":"paragraph","text":"Information about your performance on our platform.","spans":[]},
  {"type":"paragraph","text":"Images of you.","spans":[]},
  {"type":"paragraph","text":"Video recordings of the lessons you deliver via our platform.","spans":[]},
  {"type":"paragraph","text":"Information about your use of our platform.","spans":[]},
  {"type":"paragraph","text":"Information about your Internet Protocol (IP) address, browser type, browser version, the pages of our platform that you visit, the time and date of your visit to our platform, the time spent on the pages you visit, your unique device identifiers and other diagnostic data.","spans":[]},
  {"type":"paragraph","text":"Information about your time zone for location based dynamic timetabling to synchronise your calendar within the platform.","spans":[]},
  {"type":"paragraph","text":"Information about the enquiries and support requests you submit to us.","spans":[]},
  {"type":"paragraph","text":"We may also collect, store and use data relating to criminal convictions and offences where we carry out background checks on your suitability to deliver lessons via our platform. Information related to criminal convictions is subject to higher levels of protection under data protection laws. We will only collect, store and use data relating to criminal convictions and offences where the law allows us to do so.","spans":[]},
  {"type":"paragraph","text":"How is your personal information collected?","spans":[{"start":0,"end":43,"type":"strong"}]},
  {"type":"paragraph","text":"We collect personal information about our tutors through the application and onboarding process directly from the tutors themselves. We may sometimes collect additional information from third parties including former employers, credit reference agencies or other background check agencies.","spans":[]},
  {"type":"paragraph","text":"We will collect additional personal information during your use of our platform and services and during your delivery of your teaching though our platform and services.","spans":[]},
  {"type":"paragraph","text":"We collect personal information about the visitors to our website through the “Get In Touch” form on our website directly from the visitors themselves. We also collect technical information about the visitor’s use of our website through the cookies and other similar technology used by our website.","spans":[]},
  {"type":"paragraph","text":"How we will use information about you","spans":[{"start":0,"end":37,"type":"strong"}]},
  {"type":"paragraph","text":"We will only use your personal information when the law allows us to. Most commonly, we will use your personal information in the following circumstances:","spans":[]},
  {"type":"paragraph","text":"1. Where we need to perform the contract we have entered into with you.","spans":[]},
  {"type":"paragraph","text":"2. Where we need to comply with a legal obligation.","spans":[]},
  {"type":"paragraph","text":"3. Where it is necessary for legitimate interests pursued by us or a third party and your interests and fundamental rights do not override those interests.","spans":[]},
  {"type":"paragraph","text":"We may also use your personal information where we need to protect your interests (or someone else's interests), although that is likely to be rare.","spans":[]},
  {"type":"paragraph","text":"Situations in which we will use your personal information","spans":[{"start":0,"end":57,"type":"strong"}]},
  {"type":"paragraph","text":"We need all the categories of information in the list above primarily to allow us to perform our contract with you and to enable us to comply with legal obligations. In some cases, we may use your personal information to pursue legitimate interests, provided your interests and fundamental rights do not override those interests. The situations in which we will process your personal information, and the lawful basis for the processing, are listed in the table below.","spans":[]},
]

const text2 = [
  {"type":"paragraph","text":"Situations in which we process personal information","spans":[{"start":0,"end":51,"type":"strong"}]},
  {"type":"paragraph","text":"Lawful basis for processing","spans":[{"start":0,"end":27,"type":"strong"}]},
  {"type":"paragraph","text":"To make a decision about your suitability to provide your services via our platform.","spans":[]},
  {"type":"paragraph","text":"Performance of our contract with you. Our legitimate interests to ensure the quality of the tutors providing their services via our platform.","spans":[]},
  {"type":"paragraph","text":"To allow our clients and students to book your teaching services.","spans":[]},
  {"type":"paragraph","text":"Performance of our contract with you.","spans":[]},
  {"type":"paragraph","text":"To provide you with notifications and updates on class scheduling.","spans":[]},
  {"type":"paragraph","text":"Performance of our contract with you.","spans":[]},
  {"type":"paragraph","text":"To provide you with links to the virtual classroom so you can deliver your lessons to the students.","spans":[]},
  {"type":"paragraph","text":"Performance of our contract with you.","spans":[]},
  {"type":"paragraph","text":"To allow you to deliver your services to our client’s students via our platform.","spans":[]},
  {"type":"paragraph","text":"Performance of our contract with you.","spans":[]},
  {"type":"paragraph","text":"To allow you to interact with our client’s students via our Wechat mini app.","spans":[]},
  {"type":"paragraph","text":"Performance of our contract with you.","spans":[]},
  {"type":"paragraph","text":"To make video recordings of the lessons you deliver via our platform.","spans":[]},
  {"type":"paragraph","text":"Performance of our contract with you. Our legitimate interests to: (i) monitor the quality of the lessons delivered via our platform; and (ii) provide value added services to our clients by making the video recordings available to our clients and to the students (and their parents) who attended the lesson.","spans":[]},
  {"type":"paragraph","text":"To make payments to you.","spans":[]},
  {"type":"paragraph","text":"Performance of our contract with you.","spans":[]},
  {"type":"paragraph","text":"To provide you with location-based dynamic timetabling and tutor availability features.","spans":[]},
  {"type":"paragraph","text":"Our legitimate interests to ensure the timetabling and your availability within the platform is consistent with your time zone.","spans":[]},
  {"type":"paragraph","text":"To provide you with support in using our platform and services.","spans":[]},
  {"type":"paragraph","text":"Performance of our contract with you.","spans":[]},
  {"type":"paragraph","text":"To protect and safeguard the students using our platform and services.","spans":[]},
  {"type":"paragraph","text":"Our legitimate interests to protect the students using our platform and services.","spans":[]},
  {"type":"paragraph","text":"To investigate misconduct or suspected misconduct and to deal with complaints.","spans":[]},
  {"type":"paragraph","text":"Performance of our contract with you. Our legitimate interests to ensure the proper operation of our business.","spans":[]},
  {"type":"paragraph","text":"To monitor your use of our platform to ensure compliance with our rules, policies and procedures.","spans":[]},
  {"type":"paragraph","text":"Our legitimate interests to ensure the proper operation of our business.","spans":[]},
  {"type":"paragraph","text":"To provide you with feedback on the delivery of your teaching services to ensure continuous improvement.","spans":[]},
  {"type":"paragraph","text":"Our legitimate interests to ensure the continued improvement of our business.","spans":[]},
  {"type":"paragraph","text":"For our business management and planning, including accounting and auditing.","spans":[]},
  {"type":"paragraph","text":"Our legitimate interests to ensure the proper operation of our business.","spans":[]},
  {"type":"paragraph","text":"To make decisions about your continued use of our platform and services.","spans":[]},
  {"type":"paragraph","text":"Our legitimate interests to ensure the proper operation of our business.","spans":[]},
  {"type":"paragraph","text":"To deal with legal disputes involving you, our clients and / or our client’s students and parents.","spans":[]},
  {"type":"paragraph","text":"Performance of our contract with you. Our legitimate interests to ensure the proper operation of our business.","spans":[]},
  {"type":"paragraph","text":"To prevent fraud.","spans":[]},
  {"type":"paragraph","text":"To comply with our legal obligations. Our legitimate interests to protect our business from fraud.","spans":[]},
  {"type":"paragraph","text":"To ensure network and information security, including preventing unauthorised access to our computer and electronic communications systems and preventing malicious software distribution.","spans":[]},
  {"type":"paragraph","text":"Our legitimate interests to ensure the security of our systems.","spans":[]},
  {"type":"paragraph","text":"To detect, prevent and address technical issues.","spans":[]},
  {"type":"paragraph","text":"Our legitimate interests to ensure the proper operation of our business.","spans":[]},
  {"type":"paragraph","text":"To improve our platform, services and website.","spans":[]},
  {"type":"paragraph","text":"Our legitimate interests to ensure the continued improvement of our business.","spans":[]},
  {"type":"paragraph","text":"To conduct data analytics studies to review and better understand use of our platform, services and website.","spans":[]},
  {"type":"paragraph","text":"Our legitimate interests to ensure the continued improvement of our business.","spans":[]},
  {"type":"paragraph","text":"To respond to the enquiries and support requests you submit to us.","spans":[]},
  {"type":"paragraph","text":"Performance of our contract with you. Our legitimate interests to ensure the proper operation of our business.","spans":[]},
  {"type":"paragraph","text":"Where you have given your consent, to provide you with newsletters and marketing and promotional materials and other information that may be of interest to you.","spans":[]},
  {"type":"paragraph","text":"Consent.","spans":[]},
  {"type":"paragraph","text":"To notify you about changes to our platform and services.","spans":[]},
  {"type":"paragraph","text":"Performance of our contract with you.","spans":[]}]
const text3 = [
  {"type":"paragraph","text":"","spans":[]},
  {"type":"paragraph","text":"If you fail to provide personal information","spans":[{"start":0,"end":43,"type":"strong"}]},
  {"type":"paragraph","text":"If you fail to provide certain information when requested, we may not be able to perform the contract we have entered into with you, or we may be prevented from complying with our legal obligations.","spans":[]},
  {"type":"paragraph","text":"Change of purpose","spans":[{"start":0,"end":17,"type":"strong"}]},
  {"type":"paragraph","text":"We will only use your personal information for the purposes for which we collected it, unless we reasonably consider that we need to use it for another reason and that reason is compatible with the original purpose. If we need to use your personal information for an unrelated purpose, we will notify you and we will explain the legal basis which allows us to do so.","spans":[]},
  {"type":"paragraph","text":"Please note that we may process your personal information without your knowledge or consent, in compliance with the above rules, where this is required or permitted by law.","spans":[]},
  {"type":"paragraph","text":"Sensitive personal information","spans":[{"start":0,"end":30,"type":"strong"}]},
  {"type":"paragraph","text":"\"Special categories\" of particularly sensitive personal information, such as information about your health, racial or ethnic origin, sexual orientation or trade union membership, require higher levels of protection under data protection law.","spans":[]},
  {"type":"paragraph","text":"We do not currently process sensitive personal information about you.","spans":[]},
  {"type":"paragraph","text":"Information about criminal convictions","spans":[{"start":0,"end":38,"type":"strong"}]},
  {"type":"paragraph","text":"We do not currently hold information about criminal offences or convictions.","spans":[]},
  {"type":"paragraph","text":"Automated decision-making","spans":[{"start":0,"end":25,"type":"strong"}]},
  {"type":"paragraph","text":"We do not envisage that any decisions will be taken about you using automated means, however we will notify you in writing if this position changes.","spans":[]},
  {"type":"paragraph","text":"Data sharing","spans":[{"start":0,"end":12,"type":"strong"}]},
  {"type":"paragraph","text":"We will share your data with third parties, including our clients, third-party service providers and other entities in our group.","spans":[]},
  {"type":"paragraph","text":"Your data will also be made available to the students and their parents who receive (or who are considering receiving) the benefit of your teaching services.","spans":[]},
  {"type":"paragraph","text":"We require third parties to respect the security of your data and to treat it in accordance with the law.","spans":[]},
  {"type":"paragraph","text":"We will transfer your personal information outside the European Economic Area (“EEA”). However, we have put procedures in place so you can expect a similar degree of protection in respect of your personal information.","spans":[{"start":79,"end":84,"type":"strong"}]},
  {"type":"paragraph","text":"Why might you share my personal information with third parties?","spans":[{"start":0,"end":63,"type":"strong"}]},
  {"type":"paragraph","text":"We will share your personal information with third parties where required by law, where it is necessary to administer the working relationship with you or where we have another legitimate interest in doing so.","spans":[]},
  {"type":"paragraph","text":"Which third parties process my personal information?","spans":[{"start":0,"end":52,"type":"strong"}]},
  {"type":"paragraph","text":"\"Third parties\" includes our clients (including the schools, education providers or other businesses operating in the education sector we work with), our third-party service providers (including contractors and designated agents) and other entities within our group. ","spans":[]},
  {"type":"paragraph","text":"We will share your personal information with our clients for the following purposes: ","spans":[]},
  {"type":"paragraph","text":"To enable our clients to decide whether to appoint you to deliver lessons to their students via our platform;","spans":[]},
  {"type":"paragraph","text":"To enable our clients to promote you as a tutor who is available to their students and potential students (which may include publishing information about you on their websites);","spans":[]},
  {"type":"paragraph","text":"To enable you to deliver your lessons to our client’s students via our platform;","spans":[]},
  {"type":"paragraph","text":"To enable our clients and their students and parents to access and download video recordings of the lessons you deliver via our platform for their own educational purposes.","spans":[]},
  {"type":"paragraph","text":"Our clients will share your personal information with the schools, education providers, students and their parents who receive (or who are considering receiving) the benefit of your teaching services.","spans":[]},
  {"type":"paragraph","text":"We will share your personal information with our service providers including those providing payroll, administration, IT services and analytics services.","spans":[]},
  {"type":"paragraph","text":"How secure is my information with third parties?","spans":[{"start":0,"end":48,"type":"strong"}]},
  {"type":"paragraph","text":"All our clients, service providers and other entities within our group are required to take appropriate security measures to protect your personal information. We only permit them to process your personal information for specified purposes and in accordance with our instructions.","spans":[]},
  {"type":"paragraph","text":"When might you share my personal information with other entities in the group?","spans":[{"start":0,"end":78,"type":"strong"}]},
  {"type":"paragraph","text":"We will share your personal information with other entities in our group as part of our regular reporting activities on company performance, in the context of a business reorganisation or group restructuring exercise, for system maintenance support and hosting of data.","spans":[]},
  {"type":"paragraph","text":"What about other third parties?","spans":[{"start":0,"end":31,"type":"strong"}]},
  {"type":"paragraph","text":"We may share your personal information with other third parties, for example in the context of the possible sale or restructuring of the business. In this situation we will, so far as possible, share anonymised data with the other parties before the transaction completes. Once the transaction is completed, we will share your personal information with the other parties if and to the extent required under the terms of the transaction.","spans":[]},
  {"type":"paragraph","text":"We may need to share your personal information with a regulator or to otherwise comply with the law.","spans":[]},
  {"type":"paragraph","text":"We may need to share your personal information with our professional advisors, the authorities and the courts in certain situations (for example, to enforce our legal rights or to defend ourselves against allegations or claims made against us, to prevent or investigate wrongdoings or suspected wrongdoings or to protect and safeguard the students who use our platform and services).","spans":[]},
  {"type":"paragraph","text":"Transferring information outside the EEA","spans":[{"start":0,"end":40,"type":"strong"}]},
  {"type":"paragraph","text":"We will transfer the personal information we collect about you outside the EEA in order to perform our contract with you. ","spans":[]},
  {"type":"paragraph","text":"Our clients are mainly based outside the EEA so we will need to transfer your personal information outside the EEA to them. Our clients are predominantly based in China. Our data is hosted outside the EEA so we will need to transfer your personal information outside the EEA as part of the hosting arrangements.","spans":[]},
  {"type":"paragraph","text":"In addition, our clients may transfer your personal information to other third parties based outside the EEA in relation to you providing your services via our platform. For example, where our client is an agency operating in the education sector, they will share your personal information with the relevant schools.","spans":[]},
  {"type":"paragraph","text":"For some of the countries to which we transfer your personal information, there is not an adequacy decision by the European Commission in place. This means that the destination country is not deemed to provide an adequate level of protection for your personal information.","spans":[]},
  {"type":"paragraph","text":"However, to ensure that your personal information does receive an adequate level of protection we have put in place the EU Commission’s approved Model Contractual Clauses (or other equivalent mechanisms) to ensure that your personal information is treated by those third parties in a way that is consistent with and which respects the EU and UK laws on data protection.","spans":[]},
  {"type":"paragraph","text":"If you wish to object to any transfer of your personal information outside the EEA, please let us know. However, please be aware that this may mean we are unable to perform our contract with you or provide you with our services.","spans":[]},
  {"type":"paragraph","text":"Data security","spans":[{"start":0,"end":13,"type":"strong"}]},
  {"type":"paragraph","text":"We have put in place measures to protect the security of your information. Details of these measures are available upon request.","spans":[]},
  {"type":"paragraph","text":"Our third-party service providers will only process your personal information on our instructions and where they have agreed to treat the information confidentially and to keep it secure.","spans":[]},
  {"type":"paragraph","text":"We have put in place appropriate security measures to prevent your personal information from being accidentally lost, used or accessed in an unauthorised way, altered or disclosed. In addition, we limit access to your personal information to those employees, agents, contractors and other third parties who have a business need to know. They will only process your personal information on our instructions and they are subject to a duty of confidentiality.","spans":[]},
  {"type":"paragraph","text":"We have put in place procedures to deal with any suspected data security breach and will notify you and any applicable regulator of a suspected breach where we are legally required to do so.","spans":[]},
  {"type":"paragraph","text":"However, you should be aware that the transmission of information and data is never completely secure and there is a measure of risk associated with the use of any online service.","spans":[]},
  {"type":"paragraph","text":"Data retention","spans":[{"start":0,"end":14,"type":"strong"}]},
  {"type":"paragraph","text":"How long will you use my information for?","spans":[{"start":0,"end":41,"type":"strong"}]},
  {"type":"paragraph","text":"We will only retain your personal information for as long as necessary to fulfil the purposes we collected it for, including for the purposes of satisfying any legal, accounting, or reporting requirements. To determine the appropriate retention period for personal information, we consider the amount, nature, and sensitivity of the personal information, the potential risk of harm from unauthorised use or disclosure of your personal information, the purposes for which we process your personal information and whether we can achieve those purposes through other means, and the applicable legal requirements.","spans":[]},
  {"type":"paragraph","text":"In some circumstances we may anonymise your personal information so that it can no longer be associated with you, in which case we may use such information without further notice to you. ","spans":[]},
  {"type":"paragraph","text":"Rights of access, correction, erasure, and restriction","spans":[{"start":0,"end":54,"type":"strong"}]},
  {"type":"paragraph","text":"Your duty to inform us of changes","spans":[{"start":0,"end":33,"type":"strong"}]},
  {"type":"paragraph","text":"It is important that the personal information we hold about you is accurate and current. Please keep us informed if your personal information changes during your working relationship with us. You can update your personal information in your account settings via our platform.","spans":[]},
  {"type":"paragraph","text":"Your rights in connection with personal information","spans":[{"start":0,"end":51,"type":"strong"}]},
  {"type":"paragraph","text":"Under certain circumstances, by law you have the right to:","spans":[]},
  {"type":"paragraph","text":"Request access to your personal information (commonly known as a \"data subject access request\"). This enables you to receive a copy of the personal information we hold about you and to check that we are lawfully processing it.","spans":[{"start":0,"end":15,"type":"strong"}]},
  {"type":"paragraph","text":"Request correction of the personal information that we hold about you. This enables you to have any incomplete or inaccurate information we hold about you corrected.","spans":[{"start":0,"end":19,"type":"strong"}]},
  {"type":"paragraph","text":"Request erasure of your personal information. This enables you to ask us to delete or remove personal information where there is no good reason for us continuing to process it. You also have the right to ask us to delete or remove your personal information where you have exercised your right to object to processing (see below).","spans":[{"start":0,"end":16,"type":"strong"}]},
  {"type":"paragraph","text":"Object to processing of your personal information where we are relying on a legitimate interest (or those of a third party) and there is something about your particular situation which makes you want to object to processing on this ground. You also have the right to object where we are processing your personal information for direct marketing purposes.","spans":[{"start":0,"end":21,"type":"strong"}]},
  {"type":"paragraph","text":"Request the restriction of processing of your personal information. This enables you to ask us to suspend the processing of personal information about you, for example if you want us to establish its accuracy or the reason for processing it.","spans":[{"start":0,"end":38,"type":"strong"}]},
  {"type":"paragraph","text":"Request the transfer of your personal information to another party.","spans":[{"start":0,"end":21,"type":"strong"}]},
  {"type":"paragraph","text":"If you want to review, verify, correct or request erasure of your personal information, object to the processing of your personal information, or request that we transfer a copy of your personal information to another party, please contact harry@oros-education.com in writing.","spans":[{"start":240,"end":264,"type":"hyperlink","data":{"link_type":"Web","url":"mailto:harry@oros-education.com"}}]},
  {"type":"paragraph","text":"No fee usually required","spans":[{"start":0,"end":23,"type":"strong"}]},
  {"type":"paragraph","text":"You will not have to pay a fee to access your personal information (or to exercise any of the other rights). However, we may charge a reasonable fee if your request for access is clearly unfounded or excessive. Alternatively, we may refuse to comply with the request in such circumstances.","spans":[]},
  {"type":"paragraph","text":"What we may need from you","spans":[{"start":0,"end":25,"type":"strong"}]},
  {"type":"paragraph","text":"We may need to request specific information from you to help us confirm your identity and ensure your right to access the information (or to exercise any of your other rights). This is another appropriate security measure to ensure that personal information is not disclosed to any person who has no right to receive it.","spans":[]},
  {"type":"paragraph","text":"Right to withdraw consent","spans":[{"start":0,"end":25,"type":"strong"}]},
  {"type":"paragraph","text":"In the limited circumstances where you may have provided your consent to the collection, processing and transfer of your personal information for a specific purpose, you have the right to withdraw your consent for that specific processing at any time. To withdraw your consent, please contact harry@oros-education.com. Once we have received notification that you have withdrawn your consent, we will no longer process your information for the purpose or purposes you originally agreed to, unless we have another legitimate basis for doing so in law.","spans":[{"start":293,"end":317,"type":"hyperlink","data":{"link_type":"Web","url":"mailto:harry@oros-education.com"}}]},
  {"type":"paragraph","text":"The withdrawal of your consent will not affect the lawfulness of any processing carried out before your consent was withdrawn.","spans":[]},
  {"type":"paragraph","text":"Use of cookies","spans":[{"start":0,"end":14,"type":"strong"}]},
  {"type":"paragraph","text":"We use cookies and similar tracking technologies to track the activity on our platform and we hold certain information collected via cookies. For information about our use of cookies please refer to our cookies policy which is available at oros-education.com/cookies.","spans":[]},
  {"type":"paragraph","text":"Links to other sites","spans":[{"start":0,"end":20,"type":"strong"}]},
  {"type":"paragraph","text":"Our platform may contain links to other sites that are not operated by us. If you click a third-party link, you will be directed to that third party's site. We strongly advise you to review the Privacy Policy of every site you visit. ","spans":[]},
  {"type":"paragraph","text":"We have no control over, and assume no responsibility for, the content, privacy policies or practices of any third-party sites or services.","spans":[]},
  {"type":"paragraph","text":"Data privacy manager","spans":[{"start":0,"end":20,"type":"strong"}]},
  {"type":"paragraph","text":"We have appointed a data privacy manager to oversee compliance with this privacy notice. If you have any questions about this privacy notice or how we handle your personal information, please contact our data privacy manager. You have the right to make a complaint at any time to the Information Commissioner's Office (ICO), the UK supervisory authority for data protection issues.","spans":[]},
  {"type":"paragraph","text":"Changes to this privacy notice","spans":[{"start":0,"end":30,"type":"strong"}]},
  {"type":"paragraph","text":"We reserve the right to update this privacy notice at any time, and we will provide you with a new privacy notice when we make any substantial updates. We may also notify you in other ways from time to time about the processing of your personal information.","spans":[]},
  {"type":"paragraph","text":"You should review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.","spans":[]},
  {"type":"paragraph","text":"If you have any questions about this privacy notice, please contact our data privacy manager using the following contact details: harry@oros-education.com.","spans":[{"start":0,"end":155,"type":"strong"}]}];


const PrivacyPolicy = () => {
  return (
    <>
      <SEO title={`Privacy Policy`} description={``} images={``} />
      <Container>{<Title>{`Privacy Policy`}</Title>}</Container>
      <OuterContainer>
        <Container>
          <Text>{RichText.render(text1)}</Text>
          <Text>
            <TextTable>
              {text2.map((item, index)=> {
                return (
                  index < 2 ? <p key={index}><strong>{item.text}</strong></p>:
                  <p key={index}>{item.text}</p>
                )
              })}
            </TextTable>
          </Text>
          <Text>{RichText.render(text3)}</Text>
        </Container>
      </OuterContainer>
    </>
  )
}

export default PrivacyPolicy

